header{
  padding: 0;
  .interactionTransition();

  .header{
    position: fixed;
    z-index: 555;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    height: @headerHeight;
    background: @white;
    margin: 0;
    padding: 0;
    .interactionTransition();

    &.wide div.container{
      height: 100%;
      display: flex;
    }
  }

  .header--identity{
    //width: 32.2%;
    width: calc( ( ((100vw - @maxwidth) / 2) + (0.33333 * @maxwidth) ) - 1rem + 3px);
    padding-right: 0;
    height: 100%;
    float: none;
    //display: flex;
    align-items: center;
    align-content: flex-end;
    .interactionTransition();

    @media @tabL{
      width: 33%
    }

    @media @momentToShowTheHamburger{
      display: flex;
    }

    a{
      display: block;
      width: calc((1280px * 0.33333) - 1rem);
      float: right;

      @media @tabL{
        float: none;
        width: auto;
        padding-left: 1rem;
      }
    }

    img{
      display: block;
      width: auto;
      height: @headerHeight;
      //max-height: 100%;
      .interactionTransition();
    }
  }

  .header--navigation{
    //width: 67.8%;
    flex: auto;
    height: 100%;
    padding: 0;

    @media @tabL{
      width: 66.666%;
    }
  }



  // header content row
  .pageheader{
    padding-top: @headerHeight;
    padding-bottom: 0;
    border-bottom: 5rem solid @darkBlue;
    .interactionTransition();

    @media @tabP{
      padding-top: 0;
    }

    .container{
      display: flex;

      @media @smL{
        flex-direction: column-reverse;
      }

      .desk-c4{
        width: calc( ( ((100vw - @maxwidth) / 2) + 0.33333 * @maxwidth ) - 1rem + 3px );
        background: @primaryColor;
        float: none;
        //display: flex;
        //align-items: flex-end;
        position: relative;

        @media @tabL{
          width: 33.3333%;
        }
        @media @smL{
          width: 100%;
          margin-bottom: 0;
        }

        .contentElement{
          width: calc((@maxwidth * 0.33333) - 3rem);
          font-size: 1rem;
          color: @white;
          position: absolute;
          bottom: 3rem;
          right: 2rem;
          padding: 2rem;
          box-sizing: border-box;
          font-weight: 300;

          @media @tabL{
            width: calc(100% - 2rem);
            padding: 0 1rem;
          }

          @media @tabP{
            width: calc(100% - 2rem);
            padding: 0 1rem;
            right: 1rem;
          }
          @media @smL{
            width: 100%;
            position: static;
            right: 0;
            padding: 4rem 0;
          }
        }
      }

      .desk-c8{
        //width: calc( ( ((100vw - @maxwidth) / 2) + 0.66667 * @maxwidth ) );
        flex: auto;

        .contentElement{
          position: relative;
        }

        @media @tabL{
          width: 66.666%;
        }
        @media @smL{
          width: 100%;
          flex: 1;
          padding: 0 !important;
        }

        .image{
          margin-bottom: 0;
        }
      }
    }
  }



  // Mobile Styles
  @media @tabP{
      padding-top: @mobileHeaderHeight;

    .header{
      height: @mobileHeaderHeight;
    }
    .header--identity{
      width: 50%;

      img{
        margin-left: 1rem;
        max-height: @mobileHeaderHeight;
      }
    }
  }
}


.full-width header .row.pageheader .container{
  display: flex;
}


.admin header .header{
  position: fixed;
  top: 50px;
}




.scrolled{

  header{

    .header{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: @scrolledHeaderHeight;
      box-shadow: 0 0 2px rgba(0,0,0,0.6);
      z-index: 444;

      @media @smL{
        height: @mobileHeaderHeight;
      }
    }

    .header--identity{
      height: @scrolledHeaderHeight;

      @media @smL{
        height: @mobileHeaderHeight;
      }


      a{
        img{
          height: @scrolledHeaderHeight;
        }
      }
    }

    .pageheader{
      padding-top: @scrolledHeaderHeight;
    }
  }
}
