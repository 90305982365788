.page--webdesign #ce-3{

  @media @smL{

    .container{
      display: flex;
      flex-wrap: wrap;

      .content33L{
        order: 3;
      }
      .content33C{
        order: 1;
      }
      .content33R{
        order: 2;
      }
    }
  }


}

.paket{

  &.paket-s{
    background: @tertiaryColor;
    margin-top: 3rem;
    color: @primaryColor;
  }
  &.paket-m{
    background: @secondaryColor;
    margin-top: 3rem;
    color: @white;
  }
  &.paket-l{
    background: @primaryColor;
    margin-top: 1rem;
    color: @tertiaryColor;

    @media @smL{
      margin-top: 3rem;
    }
  }
}

.paket--inner{
  padding: 2rem;
}

.paket--hint{
  display: block;
  text-align: center;
  color: @primaryColor;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: -3rem;
}

.paket--name{
  font-size: 1.5rem;
  font-weight: 500;
  padding: 3rem 0 2rem 0;

  .package--name-light{
    font-weight: 300;
  }
}

.paket-s{
  .paket--name{
    color: @primaryColor;

    .package--name-light{
      color: @tertiaryMediumColor;
    }
  }
}

.paket-m{
  .paket--name{
    color: @primaryColor;

    .package--name-light{
      color: @secondaryMediumColor;
    }
  }
}

.paket-l{
  .paket--name{
    color: @white;

    .package--name-light{
      color: @tertiaryMediumColor;
    }
  }
}


.paket--description{
  font-size: 1rem;
  margin-bottom: 2rem;
}

.paket--items{
  ul{
    margin: 0;
    padding: 0;

    li{
      margin: 0 0 0.5rem 0;
      padding: 0.5rem 0 0 0;
      list-style-type: none;
      border-bottom: 1px solid rgba(0,0,0,0.1);

      &::after, &::before{display: none;}
    }
  }
}

.paket-l .paket--items ul li{
  border-bottom: 1px solid rgba(255,255,255,0.2);
}


.paket--price{
  padding: 2rem 0 0 0;
  font-weight: 500;
}