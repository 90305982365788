/**

  ALL BUTTON CLASSES


 */

// button's wrapper | type DIV
.button--wrapper{
    width: 100%;
    display: flex;
}

// wrapper's modifier in case of given additional classes
.button--hasExtraClasses{}
// each additional class will appear as .button--hasExtraClass-%addClass%

// wrapper's modifier in case of external Link
.button--external{}

// the button itself | type A
.button{
  padding: 1rem 2rem;
  color: @darkBlue;
  background: @white;

  // in edith you can add the three main colors to the button:
  &.button--primary{
    background: @primaryColor;
    color: @white;
  }

  &.button--secondary{
    background: @secondaryColor;
  }

  &.button--tertiary{
    background: @tertiaryColor;
    color: @primaryColor;
  }

  &.has--icon{
    position: relative;
    padding-left: 4rem;

    &::after{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3rem;
      background-color: @secondaryColor;
      color: @black;
      content: "";
    }

    &.has--icon-phone{
      &::after{
        text-align: center;
        font-size: 1.66rem;
        line-height: 2.2;
        font-family: "FontAwesome";
        content: "\f095";
      }
    }

    &.has--icon-mail{
      &::after{
        text-align: center;
        font-size: 1.66rem;
        line-height: 2.1;
        font-family: "FontAwesome";
        content: "\f0e0";
      }
    }

    &.has--icon-arrow{
      &::after{
        text-align: center;
        font-size: 1.5rem;
        line-height: 3.75rem;
        color: @white;
        font-family: "FontAwesome";
        content: "\f178"!important;
      }
    }
  }


  &.button--full{
    display: block;
    box-sizing: border-box;
    width: 99%;
    text-align: center;
  }
}
// each additional class will appear after button class on the link


// wrapper
.button--hasExtraClass-button--special{
  position: absolute;
  bottom: 0;
  right: -8rem;
  display: block;
  width: auto;

  @media @tabL{
    right: 0;
    left: 0;
  }

  &::before{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 105%;
    width: 200%;
    background: rgba(255,255,255,0.5);
    content: "";
    display: block;
  }
}

.button--special{
  display: block;
  padding: 5rem 2rem 1rem 8rem;
  font-size: 1rem;
  text-align: left;
  font-weight: 500;
  color: @white;
  position: relative;
  right: 0;
  .interactionTransition();

  @media @tabL{
    padding-top: 2rem;
  }

  .btn--light{
    font-weight: 300;
  }


  &::before{
    width: 3rem;
    height: 1px;
    background: @white;
    content: "";
    display: block;
    position: absolute;
    top: 6rem;
    left: 2rem;
    .interactionTransition();

    @media @tabL{
      top: auto;
      bottom: 3rem;
    }
  }
  &::after{
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-left: 5px solid @white;
    displaY: block;
    content: "";
    position: absolute;
    top: 5.7rem;
    left: 5rem;
    .interactionTransition();

    @media @tabL{
      top: auto;
      bottom: 2.7rem;
    }
  }

  &:hover,
  &:active{
    background: @primaryColor;

    &::before{
      left: 4rem;
    }
    &::after{
      left: 7rem;
    }
  }
}



// calendly floating button
div.calendly-badge-widget{
  z-index: 950;

  &.widget--no-cookie{
    position: fixed;
    right: 20px;
    bottom: 15px;
    font-size: 16px;
    line-height: 1.2em;

    .calendly-badge-content{
      display: table-cell;
      width: auto;
      height: 45px;
      padding: 0 30px;
      border-radius: 25px;
      box-shadow: rgba(0, 0, 0, 0.25) 0 2px 5px;
      text-align: center;
      vertical-align: middle;
      font-weight: 600;
      font-size: 14px;
      color: @white;
      cursor: pointer;

      a{
        display: inline-block;
        color: @white;
        font-size: 20px;
        padding-left: 30px;
      }
    }
  }
}
.calendly-badge-widget div.calendly-badge-content{
  padding-left: 60px;
  font-family: @stdFont;
  font-size: 1.25rem;
  position: relative;

  &::after{
    position: absolute;
    top: 10px;
    left: 22px;
    font-family: "FontAwesome";
    content: "\f274";
    color: @white;
    font-size: 1.5rem;
    font-weight: 400;
  }
}