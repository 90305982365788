// disable grids standard padding for divs inside .container
.navigation{
  padding: 0;
}



// Standard Navigation Styles
.navigation{


  .menu--mainnav{
    display: table;
    width: auto;
    padding-top: 44px;
    .interactionTransition();

    li{
      float: left;
      position: relative;

      &::after{
        width: 1px;
        height: 50%;
        position: absolute;
        bottom: 1.5rem;
        right: 0;
        background: @primaryColor;
        content: "";

        @media @momentToShowTheHamburger{
          display: none;
        }
      }

      &:last-child{
        &::after{
          display: none;
        }
      }

      @media @smL{
        border-bottom: 1px solid rgba(0,0,0,0.1);
      }
    }

    // li.item--hasChild
    .item--hasChild{
      position: relative;

      & > a{
        float: left;
      }

      &.submenu--in{
        a{
          background: @secondaryColor;
          color: @white;
        }
      }
    }

    a{
      display: block;
      height: 44px;
      line-height: 44px;
      padding: 1rem;
      font-size: 1.25rem;
      color: @primaryColor;

      .interactionTransition();

      &:hover{
        background: @primaryColor;
        color: @white;
      }

      @media @tabP{
        font-size: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    // li.active
    .active{

      & > a{
        font-weight: 600;
      }
    }

    .trigger--submenu{
      float: right;
      margin-top: 66px;
      color: @black;
      width: 30px;
      height: 44px;
      border: 0;
      background: none;
      text-align: left;
    }



    // Submenu Styles
    .item--submenu{
      position: absolute;
      z-index: 999;
      top: 100%;
      left: 0;
      width: auto;
      display: table;
      background: @secondaryColor;
      white-space: nowrap;
      visibility: hidden;
      -webkit-transform: translate(0, 1.5rem);
      -moz-transform: translate(0, 1.5rem);
      transform: translate(0, 1.5rem);
      opacity: 0;
      .interactionTransition();

      li{
        display: table;
        width: 100%;
        border-bottom: 1px solid alpha(@white, 20%);
      }
      a{
        text-align: left;
        padding: 0 1rem;
        height: auto;
        line-height: 44px;

        &:hover, &:active, &:focus{
          background: @black;
          color: #fff;
        }
      }
    }

    .submenu--in .item--submenu,
    .submenu--fixed .item--submenu{
      z-index: 999;
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
}







// hide subnav trigger in case of beeing able to hover
.can--hover .navigation .menu--mainnav .trigger--submenu{display: none;}



.scrolled{
  .navigation {

    .menu--mainnav {
      padding-top: 4px;
    }
  }
}






@media @momentToShowTheHamburger{

  // Mobile Menu - slided out

  header .header--navigation{
    position: fixed;
    top: @mobileHeaderHeight;
    left: 0;
    width: 85%;
    height: calc(100vh - @mobileHeaderHeight);
    display: block;
    z-index: 999;
    -webkit-transform: translate3d(-101%,0,0);
    -moz-transform: translate3d(-101%,0,0);
    -ms-transform: translate3d(-101%,0,0);
    -o-transform: translate3d(-101%,0,0);
    transform: translate3d(-101%,0,0);
    background: @tertiaryColor;
    .interactionTransition();

    .menu--mainnav{
      li{
        width: 100%;
        display: block;

        a{
          display: block;
          padding: 0 1rem;
          box-sizing: border-box;
          line-height: 44px;
          height: 44px;
          width: 100%;
        }


        &.item--hasChild{

          display: table;
          width: 100%;

          a{
            width: 80%;
            float: left;
          }

          .trigger--submenu{
            width: 20%;
            height: 44px;
            float: right;
            margin-top: 0;
            display: inline-block;
            text-align: center;
          }
        }
      }
    }
  }

  // Mobile Menu - slided in
  .off-canvas--in{

    header .header--navigation{
      -webkit-transform: translate3d(0,0,0);
      -moz-transform: translate3d(0,0,0);
      -ms-transform: translate3d(0,0,0);
      -o-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }

  }

  // second level slides
  .navigation .menu--mainnav{
    position: relative;

    a{border: 0;}

    .item--hasChild{
      position: static;
    }


    .item--submenu{
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      max-width: 80%;
      background: @medGrey;
      height: calc(100vh - @mobileHeaderHeight);
      bottom: 0;
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate3d(-102%,0,0);
      -moz-transform: translate3d(-102%,0,0);
      -ms-transform: translate3d(-102%,0,0);
      -o-transform: translate3d(-102%,0,0);
      transform: translate3d(-102%,0,0);

      li a{
        text-align: left;
      }
    }
  }

}


@media @tabL_only{
  header .header--navigation{
    width: 45%;
  }
}


@media @tabL{

  header .header--navigation{
    top: @headerHeight;
  }

  .scrolled header .header--navigation{
    top: @scrolledHeaderHeight;
  }
}

@media @tabP{
  header .header--navigation{
    top: @mobileHeaderHeight;
  }
}