//@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:300,400,400i,500,500i,600');


/**
      COLOR standard settings
 */
@white:               #fff;
@lightGrey:           #f1f1f1;
@bodyColor:           #f6f6f6;
@medGrey:             #e9eaea;
@medTextColor:        #797979;
@stdTextColor:        #333;
@darkGrey:            #2a2a2a;
@darkBlue:            #090f13;
@black:               #000;
@warningColor:        #c70c30;
@linkColor:           @primaryColor;

// IMPORTANT!! These colors come from settings inside Edith's BE!
@primaryColor:        var(--primaryColor);
@secondaryColor:      var(--secondaryColor);
@tertiaryColor:       var(--tertiaryColor);

@secondaryHighlightedColor: #bae7e7;
@primaryHighlightedColor:   #afbfcf;

@tertiaryMediumColor: #777e8a;
@secondaryMediumColor: #318787;


@footerText:          #e7dbc5;
@footerLabel:         #877d6b;




/**
      TYPOGRAPHY standard settings
 */

@stdFont: "Fira Sans Condensed", Helvetica, Calibri, sans-serif;
@headlineFont: @stdFont;

@h1size:          3rem;
@h2size:          2.75rem;
@h2sizeMobile:    1.66rem;
@h3size:          2rem;
@h3sizeMobile:    1.33rem;
@h4size:          1.66rem;
@h5size:          1.75rem;
@h6size:          1.5rem;

@stdFontSize:     1.25rem;
@stdLineHeight:   1.5;





/**
      LAYOUT standard settings
 */

@maxwidth: 1280px;

@rowStdMargin:  5rem;
@rowSlimMargin: 1rem;

@momentToShowTheHamburger:  @tabL;

@min_tabL:    ~"only screen and (min-width: 1280px)";
@tabL:        ~"only screen and (max-width: 1279px)";     // tabL == tablet in Landscape
@tabL_only:   ~"only screen and (max-width: 1279px) and (min-width: 854px)";
@tabP:        ~"only screen and (max-width: 853px)";      // tabL == tablet in Portrait
@tabP_only:   ~"only screen and (max-width: 853px) and (min-width: 621px)";
@min_tabP:    ~"only screen and (min-width: 621px)";
@smL:         ~"only screen and (max-width: 620px)";      // smL == smartphone in Landscape
@smP:         ~"only screen and (max-width: 480px)";      // smP == smartphone in Portrait
@smX:         ~"only screen and (max-width: 384px)";      // smX == very small smartphones

/* usage:

   @media @smL{
      your-css: declarations;
   }

*/

@headerHeight:          130px;
@scrolledHeaderHeight:  80px;
@mobileHeaderHeight:    66px;