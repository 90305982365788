
// wraps lists and also single date items
.dates{
  font-size: 1rem;
  padding: 1.5rem;
  background: @white;
}



// additional class on .dates
// only on SINGLE DATE items
&.dates--single{
  margin-bottom: 1.5rem;
}



// additional class on .dates
// for DATE LISTS
&.dates--list{

  // consists of <ul>:
  .dates--list-items{
    .normalizeList();

    li{
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid @lightGrey;
    }
  }


  // additional: repetetive dates
  &.dates--list-rep{}

  // additional: unique dates
  &.dates--list-once{}
}


/**

  Structure of an date-(list-)item

  div.dates--list-item-name
  div.dates--list-item-dateloc
    span.dates--list-item-date
    span.dates--list-item-divider
    span.dates--list-item-loc
  div.dates--list-item-resp
  div.dates--list-item-info

  Date, Loc, Resp and Info got a label:
  span.dates--list-label

 */

// Default styles
.dates--list-item-name{
  font-weight: 600;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.dates--list-item-dateloc{
  font-style: normal;
  margin-bottom: 0.5rem;
}

.dates--list-item-divider{
  display: none;
}

.dates--list-item-date,
.dates--list-item-loc,
.dates--list-item-startdate,
.dates--list-item-enddate,
.dates--list-item-info{
  display: block;
  margin-bottom: 1rem;

  .dates--list-label{
    .dataLabel();
  }
}

.dates--list-item-resp{
  display: block;

  .dates--list-label{
    .dataLabel(140px);
  }
}
// if a date has a certain contact
.dates--resp-contact{

  padding-top: 1.5rem;

  .dates--list-label{
    .dataLabel(100%; block);
    text-transform: none;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .contact--single{

    .contact--name{
      color: @stdTextColor;
      margin-bottom: 1.5rem;
    }

    .contact--phone,
    .contact--mail{
      margin-bottom: 1rem;
    }

  }
}