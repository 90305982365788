.testimonials{

  .contentElement{
    background: @lightGrey;

    p{
      margin: 0;
      padding: 2rem;
      font-style: italic;
      min-height: 260px;
      box-sizing: border-box;
    }


    .image{
      position: relative;

      &::before{
        width: 0;
        height: 0;
        content: "";
        border: 9px solid transparent;
        border-top: 9px solid @lightGrey;
        position: absolute;
        top: 0;
        left: 2rem;
        display: block;
        z-index: 355;
      }


      &::after{
        width: 160px;
        height: 28px;
        line-height: 28px;
        content: "TOP BEWERTUNG";
        color: @secondaryColor;
        background: @primaryColor;
        position: absolute;
        top: -12px;
        left: 50%;
        margin-left: -80px;
        display: block;
        z-index: 356;
        text-align: center;
      }

      figcaption{
        height: 76px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0.75rem;
        text-align: center;
        color: @white;
        box-sizing: border-box;

        strong{
          text-transform: uppercase;
          font-size: 1.25rem;
        }
      }
    }
  }


  .content33L .contentElement .image figcaption{
    background: rgba(8,32,52,0.6);
  }

  .content33C .contentElement .image figcaption{
    background: rgba(87,185,179,0.6);
  }

  .content33R .contentElement .image figcaption{
    background: rgba(222,236,248,0.4);
  }
}


@media @smL{
  .testimonials .container > div{
    float: none;
    display: block;
    margin: 0;
    padding: 0;


    .content33L, .content33C{
      margin-bottom: 0;
    }
  }
}