.social--bar{
  width: 70px;
  height: 100%;
  background: rgba(0,0,0,0.7);
  color: @white;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  //height: 50px;

  @media @smL{
    display: none;
  }

  .social--bar-inner{
    min-width: 420px;
    transform: rotate(-90deg);
    position: absolute;
    right: -11rem
  }


  .social--bar-cta{
    color: @secondaryColor;
    text-transform: uppercase;
    margin-right: 2rem;
    display: inline-block;
    //transform: rotate(-90deg);
  }

  a{
    color: @white;
    display: inline-block;
    //transform: rotate(-90deg);

    + a{
      margin-left: 2rem;
    }
  }
}


