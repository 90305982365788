.top--footer{
  padding: 0;
  background: @secondaryColor;
  border-top: 4px solid @primaryColor;
  font-size: 1.33rem;
  color: @darkBlue;
  line-height: 50px;

  .container{
    display: flex;
    align-items: center;

    @media @smL{
      flex-direction: column;
    }
  }
}

.top--footer-left{
  font-size: 2rem;
  text-transform: uppercase;

  @media @tabL{
    font-size: 1.5rem;
    font-weight: 400;
  }

  @media @smP{
    padding-top: 2rem;
  }

  p{
    margin: 0;
  }

}


.top--footer-center{

  a{
    color: @darkBlue;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.5rem 0;

    @media @tabL{
      font-size: 1.125rem;
    }

    @media @smL{
      display: block;
    }

    .fa{
      font-weight: normal;
    }
  }

  a + a{
    margin-left: 3rem;

    @media @smL{
      margin-left: 0;
    }
  }
}

.container > .top--footer-left,
.container > .top--footer-center{
  @media @smL{
    padding-left: 4rem;
  }
  @media @smX{
    padding-left: 1rem;
  }
}



.top--footer-right{
  display: flex;

  @media @smL{
    border-top: 1px solid @darkBlue;
    margin-top: 1rem;
    padding-top: 1rem;
    height: 72px;
  }

  a{
    width: 33%;
    margin: 0;
    height: 100px;
    border-left: 1px solid @darkBlue;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    padding-top: 2rem;

    @media @smL{
      padding-top: 1rem;
      padding-bottom: 1rem;
      height: 50px;
      border: 0;
    }

    &.footer-wa-icon img,
    &.footer-vcf-icon img,
    &.footer-fbm-icon img{
      max-width: 32px;
      height: auto;
      margin: auto;
    }
  }

  a + a + a{
    border-right: 1px solid @darkBlue;

    @media @smL{
      border: 0;
    }
  }
}