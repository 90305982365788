main .row, .container > .headingCol{
min-height: 44px;
}

.is--secondary-color{
  color: @secondaryColor;
}


.page--impressum,
.page--datenschutz{

  main{
    margin-top: (@headerHeight + 5rem);
  }

  @media @momentToShowTheHamburger{

    main{
      margin-top: 1rem;
    }
  }
}