.row{
  padding: @rowStdMargin 0;

  .row{padding: 0;}

  // for colored rows, switch to padding instead of margin
  &.has--primary-color,
  &.has--secondary-color,
  &.has--tertiary-color{
    padding: @rowStdMargin 0;
    margin: 0;
  }


  &.row--slim{
    padding: @rowSlimMargin 0;
  }

  &.bottom--slim,
  &.row--slim-bottom{
    padding-bottom: @rowSlimMargin;
  }

  &.top--slim,
  &.row--slim-top{
    padding-top: @rowSlimMargin;
  }

  &.no--padding, &.row--nopadding{
    padding: 0;

    .image{
      margin-bottom: 0;
    }

    // but for mobile we need some padding t/b
    @media @tabP{
      padding: 3rem 0;
    }
  }


  &.no--gaps{
    .container > div{
      padding-left: 0;
      padding-right: 0;

      @media @smL{
        padding-left: 1rem!important;
        padding-right: 1rem;
      }
    }
  }
}


.admin .contentElement{
  position: relative;
}



/**

    These classes can be set in BE via Dropdown.
    You have to know, which color corresponds to which class
    and set an alternative Text color, if necessary.

 */
.has--primary-color{
  background: @primaryColor;
  color: @white;
}
.has--secondary-color{
  background: @secondaryColor;
  color: @black;
}
.has--tertiary-color{
  background: @tertiaryColor;
}
.has--white-color, .has--white-background{
  background: @white;
}



.has--img-right{
  .content50{
    .content50L{
      .contentElement{
        width: 100%;
        max-width: 600px;
        float: right;
      }
    }
  }
}


.has--img-left{
  .content50{
    .content50R{
      .contentElement{
        width: 100%;
        max-width: 600px;
      }
    }
  }
}

.full-width .wide.has--img-left{
  .container{
    display: flex;
    align-items: center;

    @media @smL{
      flex-direction: column-reverse;
    }
  }
  .content66R{
    .contentElement{
      max-width: 600px;
      width: 100%;
      margin-left: 10rem;

      @media @tabL{
        margin-left: 3rem;
      }

      @media @tabP{
        margin-left: 2rem;
      }

      @media @smL{
        margin-left: 0;
      }
    }
  }
  .content33L{
    .contentElement{
      position: relative;
    }
  }
}


.headingCol{
  margin-bottom: 3rem;
}





@media @smL{
  .row{
    .container{
      .content33L,
      .content33C,
      .content66L,
      .content50L,
      .content25L,
      .content25LC,
      .content25RC,
      .headingCol{
        margin-bottom: 3rem;
      }
    }
  }


  .page--index #ce-6 .content50L .button--wrapper{
    display: none;
  }
}

@media @tabL_only{
  .page--impressum,
  .page--datenschutz{

    #ce-1{
      padding-top: 10rem;
    }
  }
}
