/**

  ALL PARAGRAPH CLASSES


 */

main p{
  margin-bottom: 1.5rem;
}

// Two possible additional Classes can be set in Edith Mode:
// the wrappers are <div> around the Parapraph(s)

// highlighted Parapgraph
.paragraph--highlighted{
  color: @primaryColor;
  font-style: italic;
  margin-bottom: 1.5rem;

  p:last-child{
    margin: 0;
  }
}

// and exposed Paragraph
.paragraph--exposed{
  font-style: italic;
  font-size: 1.33rem;
  color: @primaryColor;
  font-weight: 500;
  padding: 1rem 2rem;
  margin-bottom: 1.5rem;

  p:last-child{
    margin: 0;
  }
}