/**

  ALL DOCUMENT CLASSES


 */

// doc link's wrapper | type DIV
.doc--wrapper{}

// wrapper's modifier in case of additional Classes
.doc--hasExtraClasses{}
// each additional class will appear as .doc--hasExtraClass-%addClass%


// doc's link | type <a>
.doc--link{

  background: @primaryColor;
  color: @black;
  display: block;
  padding: 1rem 2rem 1rem 5rem;
  position: relative;

  &::after {
    font-family: "fontawesome";
    position: absolute;
    top: 1.5rem;
    left: 2rem;
    content: "\f019";
    color: @secondaryColor;
    font-size: 1.66rem;
    opacity: 0.3;
    .interactionTransition();
  }

  &:hover,
  &:active{
    &::after{
      opacity: 0.8;
    }
  }

  // Text of the Link | type SPAN
  .doc--link-text{
    display: block;
    line-height: 1;
    font-weight: 600;
  }

  // Type of the linked document | type SPAN
  .doc--link-type{
    line-height: 1.1;
    font-size: 0.75rem;
    display: inline-block;
  }

  // Size of the linked document | type SPAN
  .doc--link-size{
    line-height: 1.1;
    font-size: 0.75rem;
    display: inline-block;
  }
}

// doc's link modifier in case of link in new tab
.doc--link-external{}