.ref--animation{
  display: flex;
  width: 100%;
  margin: 2rem 0;
  padding-top: 1rem;
  border-top: 1px solid @primaryColor;
  min-height: 96px;

  p{
    width: 33%;
    height: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
  }

  .ref--label{
    font-size: 0.75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 300;
  }
}

.page--highlights{

  .content50R{

    ul li{

      &::before{
        width: 8px;
        height: 8px;
        background: @primaryColor;
        position: absolute;
        top: 50%;
        left: 0;
        content: "";
        display: block;
        border: 0;
      }
      &::after{
        width: 8px;
        height: 8px;
        background: @secondaryColor;
        position: absolute;
        top: calc(50% - 2px);
        left: 2px;
        content: "";
        display: block;
      }
    }
  }
}