.events--list{
  .normalizeList();
}


.events--single{}



.events--multiple{

  .events--item{
    margin-bottom: 1rem;
  }
}


.events--all{

  .events--item{
    margin-bottom: 1rem;
  }
}



.event--item-datetimeloc{
  font-weight: 600;
  color: @black;
}
.events--item-name{
  margin-bottom: 0.5rem;
}