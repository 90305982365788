.menu--inline{

  display: flex;
  margin: auto;

  @media @smL{
    flex-wrap: wrap;
  }


  li{

    margin: 0 1px 0 0;
    padding: 0;

    @media @smL{
      width: calc(50% - 1px);
      margin-bottom: 1px;
    }

    &::before{
      display: none;
    }

    a{
      padding: 0.33rem 1rem;
      background: @primaryColor;
      color: @primaryHighlightedColor;

      &:hover,
      &:active{
        background: @secondaryColor;
        color: @white;
      }

      @media @smL{
        display: block;
      }
    }
  }
}