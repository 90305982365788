.mobile--address{
  display: none;

  @media @momentToShowTheHamburger{
    display: block;
  }

  @media @smX{
    display: none;
  }
}

.mobile--address-inner{
  padding: 2rem 1rem;
  font-size: 1rem;

  p{
    margin-bottom: 0.5rem;
  }
}

.mobile--address-company{
  strong{
    font-weight: 400;
  }
}
.mobile--address-numbers{
  margin-top: 2rem;
}
.mobile--address-numbers a{
  display: table;
  width: 200px;
  text-align: center;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  background: @primaryColor;
  color: @white;
  .interactionTransition();

  &:hover,
  &:active{
    background: @secondaryColor;
  }
}