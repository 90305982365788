.cards{

  @media @tabL_only{

    .container{
      display: flex;

      .contentElement{
        height: 100%;
        box-sizing: border-box;
      }
    }
  }



  .content50,
  .content33,
  .content25{

    .contentElement{
      background: @white;
      box-shadow: 0 0 3px rgba(0,0,0,0.6);
      position: relative;
      padding-bottom: 5rem;

      p{
        padding: 0 3rem;
        margin-bottom: 1rem;
      }

      .image{
        margin-bottom: 3rem;
      }

      .button--wrapper{
        height: 60px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: block;

        .button{
          display: block;
          height: 60px;
          line-height: 60px;
          text-transform: uppercase;
          box-sizing: border-box;
          padding-left: 6rem;
          padding-top: 0;
          padding-bottom: 0;

          &.button--primary{
            color: @white;
          }

          &.has--icon{
            position: relative;

            &::after{
              width: 65px;
              height: 60px;
              position: absolute;
              content: "";
              top: 0;
              left: 0;
              background-color: @secondaryColor;
            }
          }
        }
      }

      @media @tabP{
        margin-bottom: 2rem;
      }
    }
  }

}