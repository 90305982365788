// define basic contacts VARS
@contactPadding: 3rem;
@contactPaddingTab: 2rem;


.contact{
  margin-bottom: 1rem;

  &.contact--has-img{
    position: relative;
    min-height: 200px;
  }


  &.contact--style-col.contact--has-img{
    .contact--name,
    .contact--position,
    .contact--data{
      width: 50%;

      @media @smL{
        width: 100%;
      }
    }

    .contact--img-mask{
      position: absolute;
      width: calc(45% - @contactPadding);
      top: @contactPadding;
      right: @contactPadding;
      bottom: @contactPadding;
      display: flex;
      align-items: center;
      overflow: hidden;

      @media @tabL{
        width: calc(45% - @contactPaddingTab);
        top: @contactPaddingTab;
        right: @contactPaddingTab;
        bottom: @contactPaddingTab;
      }


      @media @smL{
        width: 100%;
        position: static;
        margin-bottom: 2rem;
      }

      figure img{
        .halfSecondTransition();
      }

      figure:hover, figure:active{
        img{
          transform: scale(1.125);
        }
      }
    }
  }



  &.contact--style-row{

    .contact--img-mask{
      margin-bottom: 2rem;
    }
  }


}


// Column style has to be reverted in case of beeing set into a col33 or col25

.content25,
.content33L,
.content33C,
.content33R{

  .contact{
    &.contact--style-col.contact--has-img {

      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      .contact--name,
      .contact--position,
      .contact--data {
        width: 100%;
        flex-wrap: wrap;
      }

      .contact--name{
        order: 1;
      }
      .contact--phone{
        order: 2;
      }
      .contact--mail{
        order: 3;
        width: 100%;
      }

      .contact--img-mask {
        order: 4;
        position: static;
        width: 100%;
      }
    }
  }
}






.contact--single{
  padding: @contactPadding;
  background: @lightGrey;

  @media @tabL{
    padding: @contactPaddingTab;
  }

  .contact--label{
    .dataLabel();
  }




  .contact--name{
    font-weight: 600;
    color: @black;
  }
  .contact--position{
    font-style: italic;
    color: @medTextColor;
    margin-bottom: 1.25rem;
  }

  .contact--mail{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contact--address{
    margin-top: 1.25rem;
  }

  a[data-telnr]{
    color: @stdTextColor;
  }

  @media @smL{



    // clickable phone numbers
    .clickable--mobile-tel{
      padding-top: 0.33rem;
      padding-bottom: 0.33rem;
      display: inline-block;
      color: @linkColor;
    }
  }
}




/**

    Now the contact is part of a date item

 */
.dates--resp-contact{
  .contact--single{
    background: transparent;
    padding: 0;
    margin-bottom: 1rem;
  }
}