@import "font-awesome.min.css";
/* -----------------------------------------------------------

                    CASE: Full Width

----------------------------------------------------------- */


.full-width .wrapper,
.full-width .row{
  width: 100%;
}


.full-width .container{
  max-width: 1280px;
  margin: auto;
  width: 100%;
  display: block;
}

@media screen and (max-width: 620px){
  .full-width .container{
    max-width: 100%;
    display: block;
  }
}



.full-width .wide .container{width: 100%;max-width: 100vw; display: block;}
.wide .container > div:first-of-type{
  padding-left: 0;
}
.wide .container > div:last-of-type{
  padding-right: 0;

  @media @smL{
    padding-right: 1rem;
  }
}



/* -----------------------------------------------------------

                    CASE: Tile Width

----------------------------------------------------------- */


.tile .wrapper{
  max-width: 1280px;
  margin: auto;
  box-shadow: 0 0 3px #000;
}

.tile .row{
  width: 100%;
}






.container:before,
.container:after{
  display: table;
  content: " ";
}
.container:after{
  clear: both;
}

/* Sorge für das Floating der Cols */
.container > div,
.container > section,
.container > aside{
  float: left;
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
  padding: 0 1em;
  min-height: 1px;
}


/* Aussenabstände für verschachtelte .container angleichen  */
.container .container{
  margin: 0 -1em;
  max-width: calc(100% + 2rem);
  width: calc(100% + 2rem);
}







/* alles, was größer als 1280 ist, gilt als Desktop-Größe */
.desk-c0{display:none}
.desk-c1{width:8.33333%}
.desk-c2{width:16.66666%}
.desk-c3{width:25%}
.desk-c4{width:33.33333%}
.desk-c5{width:41.66666%}
.desk-c6{width:50%}
.desk-c7{width:58.33333%}
.desk-c8{width:66.66666%}
.desk-c9{width:75%}
.desk-c10{width:83.33333%}
.desk-c11{width:91.66666%}
.desk-c12{width:100%}


.desk-off-c0{margin-left:0}
.desk-off-c1{margin-left:8.33333%}
.desk-off-c2{margin-left:16.66666%}
.desk-off-c3{margin-left:25%}
.desk-off-c4{margin-left:33.33333%}
.desk-off-c5{margin-left:41.66666%}
.desk-off-c6{margin-left:50%}

@media screen and (min-width: 920px){
  .page--linktree .desk-c8{width: 50%}
  .page--linktree .desk-off-c2{margin-left: 25%}
}





/* Breakpoint für Tablets im Landscape */
@media only screen and (max-width: 1279px){

  .tabL-c0{display:none}
  .tabL-c1{width:8.33333%}
  .tabL-c2{width:16.66666%}
  .tabL-c3{width:25%}
  .tabL-c4{width:33.33333%}
  .tabL-c5{width:41.66666%}
  .tabL-c6{width:50%}
  .tabL-c7{width:58.33333%}
  .tabL-c8{width:66.66666%}
  .tabL-c9{width:75%}
  .tabL-c10{width:83.33333%}
  .tabL-c11{width:91.66666%}
  .tabL-c12{width:100%}

  .tabL-off-c0{margin-left:0}
  .tabL-off-c1{margin-left:8.33333%}
  .tabL-off-c2{margin-left:16.66666%}
  .tabL-off-c3{margin-left:25%}
  .tabL-off-c4{margin-left:33.33333%}
  .tabL-off-c5{margin-left:41.66666%}
  .tabL-off-c6{margin-left:50%}

}











/* Breakpoint für Tablets im Portrait */
@media only screen and (max-width: 853px){

  .tabP-c0{display:none}
  .tabP-c1{width:8.33333%}
  .tabP-c2{width:16.66666%}
  .tabP-c3{width:25%}
  .tabP-c4{width:33.33333%}
  .tabP-c5{width:41.66666%}
  .tabP-c6{width:50%}
  .tabP-c7{width:58.33333%}
  .tabP-c8{width:66.66666%}
  .tabP-c9{width:75%}
  .tabP-c10{width:83.33333%}
  .tabP-c11{width:91.66666%}
  .tabP-c12{width:100%}

  .tabP-off-c0{margin-left:0}
  .tabP-off-c1{margin-left:8.33333%}
  .tabP-off-c2{margin-left:16.66666%}
  .tabP-off-c3{margin-left:25%}
  .tabP-off-c4{margin-left:33.33333%}
  .tabP-off-c5{margin-left:41.66666%}
  .tabP-off-c6{margin-left:50%}

}






/* Breakpoint für kleine Tablets im Portrait und Smartphones im Landscape */
@media only screen and (max-width: 620px){

  .smL-c0{display:none}
  .smL-c1{width:8.33333%}
  .smL-c2{width:16.66666%}
  .smL-c3{width:25%}
  .smL-c4{width:33.33333%}
  .smL-c5{width:41.66666%}
  .smL-c6{width:50%}
  .smL-c7{width:58.33333%}
  .smL-c8{width:66.66666%}
  .smL-c9{width:75%}
  .smL-c10{width:83.33333%}
  .smL-c11{width:91.66666%}
  .smL-c12{width:100%}

  .smL-off-c0{margin-left:0}
  .smL-off-c1{margin-left:8.33333%}
  .smL-off-c2{margin-left:16.66666%}
  .smL-off-c3{margin-left:25%}
  .smL-off-c4{margin-left:33.33333%}
  .smL-off-c5{margin-left:41.66666%}
  .smL-off-c6{margin-left:50%}

}








/* Breakpoint für Smartphones im Portrait - iPhone 8 als aktuelle Referenz */
@media only screen and (max-width: 480px){

  .smP-c0{display:none}
  .smP-c1{width:8.33333%}
  .smP-c2{width:16.66666%}
  .smP-c3{width:25%}
  .smP-c4{width:33.33333%}
  .smP-c5{width:41.66666%}
  .smP-c6{width:50%}
  .smP-c7{width:58.33333%}
  .smP-c8{width:66.66666%}
  .smP-c9{width:75%}
  .smP-c10{width:83.33333%}
  .smP-c11{width:91.66666%}
  .smP-c12{width:100%}

  .smP-off-c0{margin-left:0}
  .smP-off-c1{margin-left:8.33333%}
  .smP-off-c2{margin-left:16.66666%}
  .smP-off-c3{margin-left:25%}
  .smP-off-c4{margin-left:33.33333%}
  .smP-off-c5{margin-left:41.66666%}
  .smP-off-c6{margin-left:50%}

}