.partner--bars{

  padding: 0 3rem;

  @media @tabP{
    padding: 0;
    margin-top: 3rem;
  }

  @media @smL{
    padding: 0;
  }

}

.partner--item{
  padding: 0.33rem 1rem;
  display: block;
  margin-bottom: 1rem;
  background: @primaryColor;

  strong{
    display: block;
    font-weight: 300;
    color: @white;
    text-transform: uppercase;
  }

  em{
    display: block;
    font-weight: 300;
    font-style: italic;
    font-size: 0.75rem;
    color: @primaryHighlightedColor;
  }
}