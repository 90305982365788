.full-width .conversion{

  .container{
    display: flex;
    align-items: center;

    @media @tabP{
      flex-direction: column-reverse;
    }
  }
}

.conversion--container{
  max-width: 600px;
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;

  @media @tabP{
    padding: 0;
  }
}

.conversion--list{
  ol{
    margin: 1rem 0 1rem 1.5rem;
    padding: 0;

    li{
      margin: 0 0 0.5rem 0;
      padding: 0;
      list-style-type: decimal;

      &::before{
        display: none;
      }
    }
  }
}

.conversion--cta{
  font-weight: 500;
  color: @primaryColor;
  padding: 1rem 0;
}

.conversion--buttons{
  margin-top: 1rem;
  display: flex;
  width: 100%;

  @media @tabL_only{
    .button.has--icon{padding-left: 1rem;padding-right: 1rem}
    .button.has--icon.has--icon-phone::after,
    .button.has--icon.has--icon-mail::after{display: none;}
  }

  a:first-child{
    margin-right: 1rem;
  }

  @media @smL{
    flex-direction: column;

    a, a:first-child{
      width: 100%;
      box-sizing: border-box;
      margin: 0 0 1rem;
    }
  }
}