/**

  ALL IMAGE CLASSES


 */

// Standard Class for all images | type FIGURE
.image{
  margin: 0 0 1rem 0;
}

// figure's modifier in case of used figcaption
.img--landscape.image--hasCaption{

}


// figure's figcaption itself | type FIGCAPTION
.image--caption{
  font-size: calc(0.875 * @stdFontSize);
}





/**
  * Standard Image
  *
 */

// class of standard image's figure. | type FIGURE
// That is the outer wrapper
.image--standard{

  // inner wrapper | type DIV
  .image--wrapper{}

  // IF LIGHTBOX: lightbox-link | type A
  .chocolat-image{}

  // image itself | type IMG
  .image--item{}

}

// figure's modifier class in case of Lightbox is active
.image--standard-lightboxed{}








/**
  * Linked Image        AKTUALLY NOT WORKING - Edith cannot set a linked image -.-
  *
 */

// class of image's figure. | type FIGURE
// That is the outer wrapper
.image--linked{

  // inner wrapper | type DIV
  .image--wrapper{}

  // image's link | type A
  .image--link{}

  // image itself | type IMG
  .image--item{}
}

// figure's modifier class in case of link in new tab
.image--linked-extern{}