.cta--row{
  //background-image: url("../globals/img/druckventil.jpg");
  //background-repeat: no-repeat;
  //background-position: right center;
  //background-size: auto;
  background: @primaryColor;

  @media @smL{
    background-image: none;
  }
}


.cta{
  padding: 5rem 0;

  @media @smL{
    padding: 0;
  }
}

.cta--heading{
  font-size: 2.25rem;
  font-weight: 300;
  color: @white;
  margin-bottom: 1rem;
}

.cta--text{
  margin-bottom: 2rem;
}

.cta--buttons{
  display: flex;

  &.cta--buttons-centered{
    justify-content: center;
  }

  @media @momentToShowTheHamburger{
    flex-direction: column;
  }

  .button:first-child{
    margin-right: 2rem;

    @media @momentToShowTheHamburger{
      margin-right: 0;
      margin-bottom: 1rem;
      display: block;
    }

    &::after{
      text-align: center;
      font-size: 1.5rem;
      line-height: 2.5;
      font-family: "FontAwesome";
      content: "\f274";
    }
  }

  .button{
    @media @momentToShowTheHamburger{
      display: block;
      margin-bottom: 1rem;
      max-width: 320px;
    }

    @media @smL{
      max-width: 100%;
    }

    &::after{
      text-align: center;
      font-size: 1.5rem;
      line-height: 2.5;
      font-family: "FontAwesome";
      content: "\f0e0";
    }
  }


  .button:last-child{
    margin-left: 2rem;

    @media @momentToShowTheHamburger{
      margin-left: 0;
      margin-bottom: 0;
      display: block;
    }

    &::after{
      text-align: center;
      font-size: 1.66rem;
      line-height: 2.5;
      font-family: "FontAwesome";
      content: "\f095";
    }
  }
}


.cta--mobile-break{
  display: none;

  @media @smL{
    display: block;
  }
}