.highlight--slider{
  background: @white url('../content/index/img/webflaggschiff-highlights.jpg') no-repeat 65vw 0;
  //background: @white;
  background-size: 33.333% auto;

  @media @tabP{
    background: @white;
  }

  .content100C{
    padding: 2rem 1rem 2rem 1rem;
  }

  h2{
    color: @darkBlue;
  }
  h3{
    margin-bottom: 9rem;

    @media @smL{
      margin-bottom: 2rem;
    }
  }

  @media @min_tabL{
    .slider--container{
      width: calc(((100vw - @maxwidth) / 2) + (@maxwidth - 2rem));
      //margin-left: calc(((100vw - @maxwidth) / 2) * -1);
    }
  }
  .slide{

    .slide--figure, figure{
      //width: 580px;
      width: 25vw;
      height: auto;


      @media screen and (max-width: 1441px){
        width: 28vw;
      }

      @media @tabL{
        width: 480px;
      }

      @media screen and (max-width: 1200px){
        width: 360px;
      }

      @media @tabP{
        width: 100%;
        height: auto;
      }
    }


    figure{
      padding-bottom: 1rem;
      padding-left: 4px;

      @media @tabP{
        padding-left: 0;
      }

      figcaption{
        font-size: 0.875rem;
        color: @medTextColor;
        padding-bottom: 1.5rem;

        strong{
          font-weight: 500;
          color: @stdTextColor;
        }
      }

      img{
        box-shadow: 0 0 3px rgba(0,0,0,0.8);
      }
    }
  }
}