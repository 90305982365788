.website--footer{

  font-size: 1rem;
  background: @tertiaryColor;
  border-top: 3px solid @medGrey;
  color: @medTextColor;
  padding: @rowStdMargin 0;
  margin: 0;

  p{
    margin-bottom: 0.875rem;

    a{
      .footerlink();
    }
  }
}

.footer-table{
  font-weight: 300;
}
.footer-table tr td:first-child{
  padding-right: 1rem;
  padding-left: 0;
}





.footer--nav{

  p strong{
    font-weight: 600;
  }

  @media @smL{
    margin-top: 2rem;
  }

  ul{
    li:first-child,
    li:nth-child(2){
      display: none;
    }
  }

  a{
    .footerlink();
  }
}


.footer--legal{
  max-width: 90%;
  margin-top: 1.5rem;
  border-top: 1px solid rgba(0,0,0,0.1);
  padding-top: 1rem;

  @media @momentToShowTheHamburger{
    margin-bottom: 2rem;
    display: block;
    width: 100%;
  }

  a{
    .footerlink();
  }

  a + a{
    margin-left: 2rem;
  }
}