// basic styles: font-size, line-height, margins etc
h1{
  font-size: @h1size;

  @media @smL{
    font-size: 2rem;
  }
}
.pageheader h1{
  font-weight: 300;
  color: @secondaryColor;
  font-size: 2.25rem;
  text-transform: uppercase;
  margin-bottom: 2rem;

  .highlighted{
    //color: @secondaryHighlightedColor;
    color: @white;
  }
}



h2{
  font-size: @h2size;
  color: @secondaryColor;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 2rem;

  @media @smL{
    font-size: @h2sizeMobile
  }
}
.has--secondary-color h2{
  color: @white;
}
.has--tertiary-color{
  h2{
    color: @primaryColor;
  }
  h3{
    color: @secondaryColor;
  }
}

h3{
  font-size: @h3size;
  font-weight: 400;
  margin-bottom: 1rem;

  @media @smL{
    font-size: @h3sizeMobile;
  }
}
h2 + h3{
  margin-top: -2rem;
}
.page--index #ce-5 h3 + p{
  margin-top: 5rem;
  @media @tabL{
    margin-top: 2rem;
  }
}
.has--secondary-color h3{
  color: @primaryColor;
}

h4{
  font-size: @h4size;
  margin-bottom: 2rem;
  color: @primaryColor;
  font-weight: 500;
}
h5{
  font-size: @h5size;
  margin-bottom: 1rem;
}
h6{
  font-size: @h6size;
  margin-bottom: 1rem;
}


header{

  h1{}
  h2{}
  h3{}
  h4{}
  h5{}
  h6{}
}


main{

  h1{}
  h2{}
  h3{}
  h4{}
  h5{}
  h6{}

}

.has--primary-color{

  h1{}
  h2{}
  h3{}
  h4{}
  h5{}
  h6{}
}

.has--secondary-color{

  h1{}
  h2{}
  h3{}
  h4{}
  h5{}
  h6{}
}

.has--tertiary-color{

  h1{}
  h2{}
  h3{}
  h4{}
  h5{}
  h6{}
}