.slide--inner{
  //background: @white;
  display: flex;
  position: relative;

  .slide--image{
    width: 100%
  }

  .slide--caption{
    position: absolute;
    top: 10%;
    left: 2rem;
    height: 80%;
    width: 33%;
    box-sizing: border-box;
    padding: 3rem 4rem;
  }
}



.highlight--slider{
  .slide--inner{
    padding-top: 1rem;

    @media @smL{
      padding-left: 1px;
      padding-right: 1px;
      box-sizing: border-box;
    }
  }
}





// Prev / Next Arrows
.slick-prev{
  width: 100px;
  height: 50px;
  background: rgba(255,255,255,0.8);
  border: 0;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 333;
  display: none !important;
}
.slick-next{
  width: 100px;
  height: 50px;
  background: rgba(255,255,255,0.8);
  border: 0;
  position: absolute;
  bottom: -1px;
  left: 102px;
  display: none !important;
}

.highlight--slider .slick-prev,
.highlight--slider .slick-next{
  display: none !important;
}


.slick-dots{
  display: flex;
  width: 100%;
  justify-content: center;

  li{
    display: inline-flex;
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0 3px;
    background: @primaryColor;
    border-radius: 50%;
    overflow: hidden;

    &::before,
    &::after{
      display: none;
    }

    &.slick-active{
      background: @secondaryColor;
    }

    button{
      width: 12px;
      height: 12px;
      display: none;
      overflow: hidden;
      opacity: 0;
    }
  }
}