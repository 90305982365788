main{
  min-height: 600px;
}

.page--datenschutz .button{
  margin-bottom: 2rem;
}

.page--linktree{

  main .row:first-child{
    margin-top: @headerHeight;
    .interactionTransition();

    @media @tabP{
      margin-top: 0;
    }
  }

  &.scrolled{
    main .row:first-child{
      margin-top: @scrolledHeaderHeight;
    }
  }

}