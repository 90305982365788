
.normalizeList(){
  padding: 0;
  margin: 0;

  li{
    list-style-type: none;
    padding: 0;
    margin: 0;

    &:before,
    &:after{
      display: none;
    }
  }
}

.absolute(@top, @left){
  position: absolute;
  top: @top;
  left: @left;
}


.dataLabel( @stdLabelWidth: 85px; @stdDisplay: inline-block; ){
  font-style: normal;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: @medTextColor;
  display: @stdDisplay;
  width: @stdLabelWidth;
}


.halfSecondTransition(){
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -ms-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}

.oneSecondTransition(){
  -webkit-transition: 1s ease-out;
  -moz-transition: 1s ease-out;
  -ms-transition: 1s ease-out;
  -o-transition: 1s ease-out;
  transition: 1s ease-out;
}

.interactionTransition(){
  -webkit-transition: 0.33s ease;
  -moz-transition: 0.33s ease;
  -ms-transition: 0.33s ease;
  -o-transition: 0.33s ease;
  transition: 0.33s ease;
}


.footerlink(){
  font-family: @stdFont;
  color: @medTextColor;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;

  &:hover, &:focus, &:active{
    color: @black;
    text-decoration: underline;
  }
}